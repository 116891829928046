import { ModalProvider, ToastProvider } from '@rocketmakers/armstrong-edge';
import * as React from 'react';
import { CookiesProvider } from 'react-cookie';

// eslint-disable-next-line react/display-name, react/prop-types -- so name doesn't have to be provided for component
export default ({ element }) => (
  <CookiesProvider>
    <ToastProvider>
      <ModalProvider>{element}</ModalProvider>
    </ToastProvider>
  </CookiesProvider>
);
